import { parseWithZod } from '@conform-to/zod'
import { datadogRum } from '@datadog/browser-rum'
import { invariantResponse } from '@epic-web/invariant'
import {
	json,
	type ActionFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
	type SerializeFrom,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useFetchers,
	useLoaderData,
	useMatches,
} from '@remix-run/react'
import { StatsigProvider } from '@statsig/react-bindings'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary'
import { Toaster } from '#app/components/ui/toaster'
import tailwindStyleSheetUrl from '#app/styles/tailwind.css?url'
import { getUserId, logout } from '#app/utils/auth.server'
import { ClientHintCheck, getHints } from '#app/utils/client-hints'
import { getEnv } from '#app/utils/env.server'
import { honeypot } from '#app/utils/honeypot.server'
import { combineHeaders, getDomainUrl } from '#app/utils/misc'
import { useNonce } from '#app/utils/nonce-provider'
import { getTheme, setTheme, type Theme } from '#app/utils/theme.server'
import { makeTimings, time } from '#app/utils/timing.server'
import { getToast } from '#app/utils/toast.server'
import { getUserSelf } from './.server/api-client/sdk.gen'
import { TooltipProvider } from './components/ui/tooltip'
import { getApiClient } from './utils/service-api.server'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		// { rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
		// { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		// { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Moonhub Recruiter' : 'Error | Moonhub Recruiter' },
		{ name: 'description', content: `Worlds first AI recruiter` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const client = await getApiClient(request)
	const user = userId ? await getUserSelf({ client }) : null

	if (userId && !user?.data) {
		console.info(
			'something weird happened: root',
			JSON.stringify({ userId, user }),
		)
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		return await logout({ request, redirectTo: '/' })
	}

	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user: user?.data,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
}) {
	return (
		<html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1"
				/>
				<Links />
			</head>
			<body className="h-full bg-background text-base font-normal text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<Toaster />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const theme = useTheme()

	if (data.user) {
		datadogRum.setUser({
			id: data.user.id,
			name: data.user.name,
			email: data.user.email ?? undefined,
		})
	}

	return (
		<StatsigProvider
			sdkKey={ENV.STATSIG_CLIENT_KEY}
			user={{
				userID: data?.user?.auth_id,
				email: data?.user?.email ?? undefined,
			}}
		>
			<Document nonce={nonce} theme={theme} env={data.ENV}>
				<Outlet />
			</Document>
		</StatsigProvider>
	)
}

export default function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<TooltipProvider delayDuration={500}>
				<App />
			</TooltipProvider>
		</HoneypotProvider>
	)
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme(): Theme {
	// const hints = useHints()
	// const requestInfo = useRequestInfo()
	// const optimisticMode = useOptimisticThemeMode()
	// if (optimisticMode) {
	// 	return optimisticMode === 'system' ? hints.theme : optimisticMode
	// }
	// return requestInfo.userPrefs.theme ?? hints.theme
	return 'light'
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find((f) => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}

export function useRootUserData(): SerializeFrom<typeof loader> | undefined {
	// find our match
	const match = useMatches().find((m) => m.id === 'root')
	if (!match?.data) return
	const data = match.data as SerializeFrom<typeof loader>

	return data
}
