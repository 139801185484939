import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const gradientTextVariantDefinitions = {
	moonhub: 'from-brand-start to-brand-end',
} as const

const gradientTextVariants = cva('inline-block text-transparent bg-clip-text', {
	variants: {
		variant: gradientTextVariantDefinitions,
		reverse: {
			true: 'bg-gradient-to-l',
			false: 'bg-gradient-to-r',
		},
	},
	defaultVariants: {
		variant: 'moonhub',
		reverse: false,
	},
})

export interface GradientTextProps
	extends React.ButtonHTMLAttributes<HTMLDivElement>,
		VariantProps<typeof gradientTextVariants> {
	reverse?: boolean
}

const GradientText = React.forwardRef<HTMLDivElement, GradientTextProps>(
	({ className, variant, reverse, children, ...props }, ref) => {
		return (
			<div
				className={cn(
					gradientTextVariants({ variant, reverse, className }),
				)}
				ref={ref}
				{...props}
			>
				{children}
			</div>
		)
	},
)
GradientText.displayName = 'GradientText'

export { GradientText, gradientTextVariants }
