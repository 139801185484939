import { Slot } from '@radix-ui/react-slot'
import { Link, type LinkProps } from '@remix-run/react'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const buttonVariants = cva(
	'inline-flex items-center justify-center rounded-md ring-offset-background transition-colors outline-none focus-visible:ring-2 focus-within:ring-2 ring-ring ring-offset-2 disabled:pointer-events-none disabled:opacity-50 gap-x-2 leading-none',
	{
		variants: {
			variant: {
				default:
					'bg-primary text-primary-foreground hover:bg-primary/80',
				white: 'bg-white hover:bg-gray-100 border border-button',
				destructive:
					'bg-destructive text-destructive-foreground hover:bg-destructive/80',
				outline:
					'border border-button bg-transparent hover:bg-[#FAFAFA] hover:text-accent-foreground',
				primaryOutline:
					'border border-button bg-transparent hover:bg-[#EEECFE] border-primary text-primary ring-offset-[#EEECFE]',
				secondary:
					'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				ghost: 'border border-transparent hover:border-border hover:bg-[#FAFAFA] hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
				moonhub:
					'from-brand-start to-brand-end bg-gradient-to-r text-white hover:shadow-md hover:from-brand-start/90 hover:to-brand-end/90',
			},
			size: {
				default: 'h-10 px-4 py-2 rounded-3xl',
				wide: 'px-24 py-5',
				xs: 'h-7 rounded-full px-3 py-0 gap-x-1',
				sm: 'h-9 rounded-full px-4',
				lg: 'h-11 rounded-3xl px-8',
				xl: 'h-14 rounded-3xl px-10 text-xl',
				'2xl': 'h-16 rounded-3xl px-10 text-4xl',
				pill: 'px-12 py-3 leading-3',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

export interface LinkButtonProps
	extends Omit<LinkProps, 'to'>,
		VariantProps<typeof buttonVariants> {
	to:
		| string
		| { pathname: string; search?: string; hash?: string; state?: any }
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		)
	},
)
Button.displayName = 'Button'

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
	({ className, variant, size, asChild = false, to, ...props }, ref) => {
		const Comp = asChild ? Slot : Link
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				to={to}
				ref={ref}
				{...props}
			/>
		)
	},
)
LinkButton.displayName = 'LinkButton'

export { Button, buttonVariants, LinkButton }
